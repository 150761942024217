/* eslint-disable func-names */
/* eslint-disable react/button-has-type */

import React, { useEffect } from "react";
import "./jiraIssueCollector.css"; // Import your CSS file

function JiraIssueCollector() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://tekvo.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/tod1zk/b/4/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-GB&collectorId=675fe1d9";
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      const cWindow: any = window;
      cWindow.ATL_JQ_PAGE_PROPS = {
        triggerFunction(showCollectorDialog: any) {
          const element = document.getElementById("myCustomTrigger");
          if (element) {
            element.addEventListener("click", function (e) {
              e.preventDefault();
              showCollectorDialog();
            });
          }
        },
      };
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="jira-collector-container">
      <button className="jira-collector-button" id="myCustomTrigger">
        Raise a bug
      </button>
    </div>
  );
}

export default JiraIssueCollector;
