import { Box, Card, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { AlertColor } from "@mui/material/Alert";
import palette from "theme/palette";

type IProps = {
  open: boolean;
  title: string;
  type: AlertColor;
  handleClose: () => void;
};

function Snackbar(props: IProps) {
  const { open, title, type, handleClose } = props;

  return (
    <Box
      sx={{
        display: open ? "block" : "none",
        position: "fixed",
        bottom: 5,
        left: 10,
        zIndex: 99999,
      }}
    >
      <Card
        sx={{
          marginBottom: "12px",
          boxShadow: "2.00774px 1.20464px 2.00774px rgba(0, 0, 0, 0.1)",
          padding: 0,
          "&:last-child": {
            paddingBottom: "0",
          },
          borderRadius: "6px",
        }}
      >
        <Stack
          alignItems="center"
          borderRadius="10px"
          direction="row"
          justifyContent="space-between"
        >
          <Box
            sx={{
              backgroundColor:
                type === "error" ? palette.error.main : palette.success.main,
              width: "10px",
              height: "50px",
              py: "0px",
            }}
          />
          <Box p="10px">
            <Typography sx={{ marginTop: "8px", fontSize: "13px", pl: "10px" }}>
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
}

export default Snackbar;
