import ExpirePackagesLimit from "ExpirePackagesLimit";
import ErrorBoundary from "components/ErrorBoundary";
import LoginExpireAlerts from "components/ExpireAlerts/LoginExpireAlerts";
import JiraIssueCollector from "components/Issue-Collector";
import Routes from "navigation";

function App() {
  // eslint-disable-next-line no-console
  console.log("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT); // this console use to identify env
  return (
    <ErrorBoundary>
      <Routes />
      <ExpirePackagesLimit />
      <LoginExpireAlerts />
      <JiraIssueCollector />
    </ErrorBoundary>
  );
}

export default App;
