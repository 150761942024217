import { Button, Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Imagespath from "assets/images";
import { useNavigate } from "react-router-dom";
import AppRoutes from "navigation/appRoutes";
import useUserInfo from "hooks/useUserInfo";
import { useAuthActions } from "redux/slices/auth";
import palette from "theme/palette";

export default function ExpirePackagesLimit() {
  const navigation = useNavigate();
  const userInformation = useUserInfo();
  const { setIsLimitExpired } = useAuthActions();
  const handelNavigation = (route: string) => {
    navigation(route);
  };

  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={userInformation?.isLimitExpired}
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "15px",
              color: palette.error.main,
            }}
          >
            Alert! Package limits has expired
          </Typography>
          <Box sx={{ display: "flex", padding: 2 }}>
            <img
              alt="Warning"
              src={Imagespath?.Warning}
              style={{ height: 100, margin: "auto" }}
            />
          </Box>
          <Typography
            sx={{
              color: "#a9a9a9",
              textAlign: "center",
              fontSize: "11px",
              maxWidth: "300px",
            }}
          >
            Please upgrade package to expand your Limits. Do not Worry, We kept
            all of your form and tables in one place
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            sx={{
              textTransform: "none",
              margin: "auto",
              alignSelf: "baseline",
              marginBottom: "10px",

              backgroundColor: palette.error.main,
              "&:hover": {
                backgroundColor: palette.error.dark,
              },
              "&:disabled": {
                backgroundColor: palette.error.light,
              },
            }}
            variant="contained"
            onClick={() => {
              setIsLimitExpired({ isLimitExpired: false });
            }}
          >
            Close
          </Button>
          <Button
            sx={{
              textTransform: "none",
              margin: "auto",
              alignSelf: "baseline",
              marginBottom: "10px",
            }}
            variant="contained"
            onClick={() => {
              setIsLimitExpired({ isLimitExpired: false });
              handelNavigation(AppRoutes?.PACKAGES);
            }}
          >
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
