import { Spinner } from "components/Loader";
import Loadable from "react-loadable";

export const Settings = Loadable({
  loader: () => import("pages/Admin/Settings"),
  loading: () => <Spinner />,
});

export const Screens = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/Screens"),
  loading: () => <Spinner />,
});

export const ScreenAccess = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/AssignPermissions"),
  loading: () => <Spinner />,
});

export const Permissions = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/Permissions"),
  loading: () => <Spinner />,
});

export const Roles = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/Roles"),
  loading: () => <Spinner />,
});
export const Users = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/Users"),
  loading: () => <Spinner />,
});
export const Invites = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/Invite"),
  loading: () => <Spinner />,
});

export const AssignTables = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/AssignTables"),
  loading: () => <Spinner />,
});

export const Groups = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/Groups"),
  loading: () => <Spinner />,
});

export const RoleDetails = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/Roles/RolesDetails"),
  loading: () => <Spinner />,
});

export const AccessTableMembers = Loadable({
  loader: () =>
    import(
      "pages/Admin/Settings/Screens/AssignTables/Screens/AssignTableMembers"
    ),
  loading: () => <Spinner />,
});
export const AccessFormMembers = Loadable({
  loader: () =>
    import(
      "pages/Admin/Settings/Screens/AssignForms/Screens/AssignTableMembers"
    ),
  loading: () => <Spinner />,
});

export const FormsGroups = Loadable({
  loader: () => import("pages/Admin/Forms/FormsGroups/index"),
  loading: () => <Spinner />,
});

export const FormsTheme = Loadable({
  loader: () => import("pages/Admin/Forms/FormsTheme/index"),
  loading: () => <Spinner />,
});
export const FormsThemePreview = Loadable({
  loader: () => import("pages/Admin/Forms/FormsTheme/ThemePreview"),
  loading: () => <Spinner />,
});
export const FormThemeCreate = Loadable({
  loader: () => import("pages/Admin/Forms/FormsTheme/FormThemeCreate"),
  loading: () => <Spinner />,
});

export const AssignForms = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/AssignForms"),
  loading: () => <Spinner />,
});

export const APIKeys = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/APIKeys"),
  loading: () => <Spinner />,
});
