import { Spinner } from "components/Loader";
import Loadable from "react-loadable";

export const Landing = Loadable({
  loader: () => import("pages/Auth/Landing"),
  loading: () => <Spinner />,
});

export const Login = Loadable({
  loader: () => import("pages/Auth/Login"),
  loading: () => <Spinner />,
});

export const SingUp = Loadable({
  loader: () => import("pages/Auth/Signup"),
  loading: () => <Spinner />,
});

export const VerifyAccount = Loadable({
  loader: () => import("pages/Auth/VerifyAccount"),
  loading: () => <Spinner />,
});

export const ForgotPassword = Loadable({
  loader: () => import("pages/Auth/ForgotPassword"),
  loading: () => <Spinner />,
});
export const ResetPassword = Loadable({
  loader: () => import("pages/Auth/ResetPassword"),
  loading: () => <Spinner />,
});

export const Invitation = Loadable({
  loader: () => import("pages/Auth/Invitation"),
  loading: () => <Spinner />,
});

export const PagesNotFind = Loadable({
  loader: () => import("pages/Auth/PageNotFind"),
  loading: () => <Spinner />,
});

export const Form = Loadable({
  loader: () => import("pages/Auth/Form"),
  loading: () => <Spinner />,
});

export const Packages = Loadable({
  loader: () => import("pages/Auth/Packages"),
  loading: () => <Spinner />,
});

export const GoogleAuthCallBack = Loadable({
  loader: () => import("pages/Auth/GoogleAuthCallBack"),
  loading: () => <Spinner />,
});