import { createSlice } from "@reduxjs/toolkit";
import { IScreen } from "interfaces/settings/screen.interface";
import { useAppDispatch } from "redux/store";

export interface IScreenReducer {
  screen: IScreen | null;
}

const initialState: IScreenReducer = {
  screen: null,
};

interface IPayload {
  screen: IScreen;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setScreen: (state, action: IAction) => {
      const newState = state;
      newState.screen = action.payload.screen;
    },
    removeScreen: (state) => {
      const newState = state;
      newState.screen = null;
    },
  },
});

export const { setScreen, removeScreen } = usersSlice.actions;

export const useScreenActions = () => {
  const dispatch = useAppDispatch();
  return {
    setScreen: (payload: IPayload) => dispatch(setScreen(payload)),
    removeScreen: () => dispatch(removeScreen()),
  };
};
const { reducer } = usersSlice;

export default reducer;
