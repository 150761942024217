import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface IUserState {
  token: string;
  fullName?: string;
  isLoggedIn: boolean;
  userWorkspaceName: string;
  userWorkspaceSetID: string;
  isNotionConnected: string;
  openTokenModel: boolean;
  storageData: any;
  packageName: string;
  isLimitExpired: boolean;
  roleName: string;
  isDefaultTheme: string;
}

const initialState: IUserState = {
  token: "",
  isLoggedIn: false,
  userWorkspaceSetID: "",
  userWorkspaceName: "",
  isNotionConnected: "",
  openTokenModel: false,
  storageData: null,
  packageName: "",
  isLimitExpired: false,
  roleName: "",
  isDefaultTheme: "",
};

interface IPayload {
  isLoggedIn: boolean;
  token: string;
  userWorkspaceSetID?: string;
}

interface IAction {
  payload: IPayload;
  type: string;
}

interface IWorkSpaceIDPayload {
  userWorkspaceSetID?: string;
  userWorkspaceName?: string;
  isNotionConnected?: string;
  openTokenModel?: boolean;
  storageData?: any;
  packageName?: string;
  isLimitExpired?: boolean;
  roleName?: string;
  isDefaultTheme?: string;
}

interface IWorkSpaceIDAction {
  payload: IWorkSpaceIDPayload;
  type: string;
}
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserCredential: (state, action: IAction) => {
      const newState = state;
      newState.isLoggedIn = action.payload.isLoggedIn;
      newState.token = action.payload.token;
      newState.userWorkspaceSetID = action.payload.userWorkspaceSetID || "";
    },
    setUserWorkspaceSetID: (state, action: IWorkSpaceIDAction) => {
      const newState = state;
      if (newState) {
        newState.userWorkspaceSetID = action.payload.userWorkspaceSetID || "";
        newState.userWorkspaceName = action.payload.userWorkspaceName || "";
        newState.isNotionConnected = action.payload.isNotionConnected || "";
        newState.storageData = action.payload.storageData || "";
        newState.packageName = action.payload.packageName || "";
        newState.roleName = action.payload.roleName || "";
      }
    },
    setNotionConnected: (state, action: any) => {
      const newState = state;
      newState.isNotionConnected = action.payload.isNotionConnected || "";
    },
    setNotionModel: (state, action: any) => {
      const newState = state;
      newState.openTokenModel = action.payload.openTokenModel;
    },
    setStorageData: (state, action: any) => {
      const newState = state;
      if (newState.storageData)
        newState.storageData = action.payload.storageData || "";
    },
    setIsLimitExpired: (state, action: any) => {
      const newState = state;
      newState.isLimitExpired = action.payload.isLimitExpired || "";
    },
    setDefaultTheme: (state, action: IWorkSpaceIDAction) => {
      const newState = state;
      newState.isDefaultTheme = action.payload.isDefaultTheme || "";
    },
  },
});

export const {
  setUserCredential,
  setUserWorkspaceSetID,
  setStorageData,
  setNotionConnected,
  setNotionModel,
  setIsLimitExpired,
  setDefaultTheme,
} = authSlice.actions;

export const useAuthActions = () => {
  const dispatch = useAppDispatch();
  return {
    setUserCredential: (payload: IPayload) =>
      dispatch(setUserCredential(payload)),
    setUserWorkspaceSetID: (payload: IWorkSpaceIDPayload) =>
      dispatch(setUserWorkspaceSetID(payload)),
    setDefaultTheme: (payload: IWorkSpaceIDPayload) =>
      dispatch(setDefaultTheme(payload)),
    setNotionConnected: (payload: any) => dispatch(setNotionConnected(payload)),
    setStorageData: (payload: any) => dispatch(setStorageData(payload)),
    setNotionModel: (payload: any) => dispatch(setNotionModel(payload)),
    setIsLimitExpired: (payload: any) => dispatch(setIsLimitExpired(payload)),
  };
};
const { reducer } = authSlice;
export default reducer;
