/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import { IInvite } from "interfaces/settings/invite.interface";
import { useAppDispatch } from "redux/store";

interface IValues {
  _id: string;
  themeName: string;
  backgroundColor: string;
  textColor: string;
  buttonText: string;
  buttonBgColor: string;
  borderColor: string;
  inputBgColor: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface IThemeReducer {
  theme: IValues | null;
}

const initialState: IThemeReducer = {
  theme: null,
};

interface IPayload {
  theme: IValues | null;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const inviteSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setTheme: (state, action: IAction) => {
      const newState = state;
      newState.theme = action.payload.theme;
    },
    removeTheme: (state) => {
      const newState = state;
      newState.theme = {
        _id: "",
        themeName: "",
        backgroundColor: "",
        textColor: "",
        buttonText: "",
        buttonBgColor: "",
        borderColor: "",
        inputBgColor: "",
        createdAt: "",
        updatedAt: "",
        __v: 0,
      };
    },
  },
});

export const { setTheme, removeTheme } = inviteSlice.actions;

export const useThemeEditActions = () => {
  const dispatch = useAppDispatch();
  return {
    setTheme: (payload: IPayload) => dispatch(setTheme(payload)),
    removeTheme: () => dispatch(removeTheme()),
  };
};
const { reducer } = inviteSlice;

export default reducer;
