import Connection from "./Connection.jpg";
import Interigation from "./Interigation.png";
import Landing_page from "./Landing_page.png";
import Warning from "./warning.jpg";
import PageNotFound from "./pageNotFound.jpg";
import Message from "./Message.png";
import Product from "./Product.png";
import GoogleIcon from "./google-icon.png";

const Imagespath = {
  Landing_page,
  Connection,
  Interigation,
  Warning,
  PageNotFound,
  Message,
  Product,
  GoogleIcon,
};

export default Imagespath;
