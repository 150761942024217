import { Spinner } from "components/Loader";
import Loadable from "react-loadable";

export const Dashboard = Loadable({
  loader: () => import("pages/Admin/Dashboard"),
  loading: () => <Spinner />,
});

export const Tables = Loadable({
  loader: () => import("pages/Admin/Tables"),
  loading: () => <Spinner />,
});

export const Integration = Loadable({
  loader: () => import("pages/Admin/Integration"),
  loading: () => <Spinner />,
});

export const Instructions = Loadable({
  loader: () => import("pages/Admin/Integration/components/Instructions"),
  loading: () => <Spinner />,
});

export const CreateNativeTable = Loadable({
  loader: () => import("pages/Admin/Tables/NativeTables/CreateTable"),
  loading: () => <Spinner />,
});

export const Forms = Loadable({
  loader: () => import("pages/Admin/Forms"),
  loading: () => <Spinner />,
});

export const CreateForm = Loadable({
  loader: () => import("pages/Admin/Forms/CreateForm"),
  loading: () => <Spinner />,
});

export const AcceptInvitation = Loadable({
  loader: () => import("pages/Admin/AcceptInvitation"),
  loading: () => <Spinner />,
});

export const NotionTableData = Loadable({
  loader: () => import("pages/Admin/Tables/NotionTableData"),
  loading: () => <Spinner />,
});

export const SelectWorkSpace = Loadable({
  loader: () => import("pages/Admin/SelectWorkSpace"),
  loading: () => <Spinner />,
});

export const Profile = Loadable({
  loader: () => import("pages/Admin/Profile"),
  loading: () => <Spinner />,
});

export const Templates = Loadable({
  loader: () => import("pages/Admin/Templates"),
  loading: () => <Spinner />,
});

export const TemplateReview = Loadable({
  loader: () => import("pages/Admin/Templates/TemplateReview"),
  loading: () => <Spinner />,
});

export const SelectedTables = Loadable({
  loader: () => import("pages/Admin/Templates/SelectedTables"),
  loading: () => <Spinner />,
});
