/* eslint-disable react-hooks/rules-of-hooks */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface IAccessTableReducer {
  member: [] | null;
}

const initialState: IAccessTableReducer = {
  member: [],
};

interface IPayload {
  member: any;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const accessTableMembers = createSlice({
  name: "accessTableMember",
  initialState,
  reducers: {
    setMembers: (state, action: IAction) => {
      const newState = state;
      newState.member = action.payload.member;
    },
    removeMembers: (state) => {
      const newState = state;
      newState.member = null;
    },
  },
});

export const { setMembers, removeMembers } = accessTableMembers.actions;

export const useAccessTableMemberActions = () => {
  const dispatch = useAppDispatch();
  return {
    setMembers: (payload: IPayload) => dispatch(setMembers(payload)),
    removeMembers: () => dispatch(removeMembers()),
  };
};
const { reducer } = accessTableMembers;

export default reducer;
