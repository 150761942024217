import { createSlice } from "@reduxjs/toolkit";
import { IRole } from "interfaces/settings/role.interface";
import { useAppDispatch } from "redux/store";

export interface IRoleReducer {
  role: IRole | null;
}

const initialState: IRoleReducer = {
  role: null,
};

interface IPayload {
  role: IRole;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    setRole: (state, action: IAction) => {
      const newState = state;
      newState.role = action.payload.role;
    },
    removeRole: (state) => {
      const newState = state;
      newState.role = null;
    },
  },
});

export const { setRole, removeRole } = roleSlice.actions;

export const useRoleActions = () => {
  const dispatch = useAppDispatch();
  return {
    setRole: (payload: IPayload) => dispatch(setRole(payload)),
    removeRole: () => dispatch(removeRole()),
  };
};
const { reducer } = roleSlice;

export default reducer;
