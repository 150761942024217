/* eslint-disable no-unsafe-optional-chaining */
import { Button, Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Imagespath from "assets/images";
import useDecodedData from "hooks/useDecodedData";
import { useEffect, useState } from "react";

interface IResponseState {
  isExpire: boolean;
  message: string;
}
export default function LoginExpireAlerts() {
  // costume hooks
  const userInfo: any = useDecodedData();
  // local states
  const [open, setOpen] = useState(true);
  const [response, setResponse] = useState<IResponseState>({
    message: "",
    isExpire: false,
  });

  // functions to handel  calculations
  const handelLoginExpireReminder = async () => {
    try {
      const exp = userInfo?.exp * 1000;
      const iat = userInfo?.iat * 1000;
      const timeDifferenceMs = exp - iat;
      const timeDifferenceDate = new Date(timeDifferenceMs);
      const days = timeDifferenceDate.getUTCDate() - 1; // Subtract 1 because Date counts days starting from 1
      const hours = timeDifferenceDate.getUTCHours();
      const minutes = timeDifferenceDate.getUTCMinutes();
      if (days === 0 && hours === 0) {
        if (minutes <= 10) {
          setResponse({
            message: `your login session is expire in ${minutes} minutes`,
            isExpire: true,
          });
          return true;
        }
        setResponse({
          message: "",
          isExpire: false,
        });
        return true;
      }
      setOpen(true);
      return true;
    } catch (e) {
      setResponse({
        message: "",
        isExpire: false,
      });
      return true;
    }
  };
  useEffect(() => {
    setOpen(true);
    const intervalId = setInterval(handelLoginExpireReminder, 60000 * 3);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        open={open && response?.isExpire}
      >
        <Box sx={{ display: "flex", padding: 2 }}>
          <img
            alt="Warning"
            src={Imagespath?.Warning}
            style={{ height: 200, margin: "auto" }}
          />
        </Box>
        <DialogTitle id="alert-dialog-title">
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            LockBoxy Alert
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ paddingX: "10px" }}
          >
            <Typography
              sx={{ color: "#a9a9a9", textAlign: "center", fontSize: "12px" }}
            >
              session is about to expire please save your work and re-login
            </Typography>
            <Typography
              sx={{ color: "#a9a9a9", textAlign: "center", fontSize: "12px" }}
            >
              {response.message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              borderRadius: 20,
              textTransform: "none",
              margin: "auto",
              alignSelf: "baseline",
              marginBottom: "25px",
            }}
            variant="contained"
            onClick={() => setOpen(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
