import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface IPermissionGuard {
  permissionList: any;
  key: [];
  screenCode: [];
}

const initialState: IPermissionGuard = {
  permissionList: {},
  key: [],
  screenCode: [],
};

const authSlice = createSlice({
  name: "permissionGuard",
  initialState,
  reducers: {
    setPermissionList: (state, action: any) => {
      const newState: any = state;
      newState.permissionList = {
        ...newState.permissionList,
        ...action.payload.permissionList,
      };
      newState.key.push(action.payload.key);
      newState.screenCode.push(action.payload.screenCode);
    },
    setPermissionListEmpty: (state) => {
      const newState: any = state;
      newState.key = [];
      newState.screenCode = [];
      newState.permissionList = {};
    },
  },
});

export const { setPermissionList, setPermissionListEmpty } = authSlice.actions;

export const usePermissionListActions = () => {
  const dispatch = useAppDispatch();
  return {
    setPermissionList: (payload: any) => dispatch(setPermissionList(payload)),
    setPermissionListEmpty: () => dispatch(setPermissionListEmpty()),
  };
};
const { reducer } = authSlice;
export default reducer;
