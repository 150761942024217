import "./index.css";

import { ThemeProvider } from "@mui/system";
import { SnackbarProvider } from "components/Snackbar";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";

import { AlertProvider } from "components/Alert";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

const consoleWarn = console.warn;
const SUPPRESSED_WARNINGS = [
  "If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase",
  "If you accidentally passed it from a parent component, remove it from the DOM element",
];

console.warn = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
    consoleWarn(msg, ...args);
  }
};
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <AlertProvider>
              <QueryClientProvider client={queryClient}>
                <Router>
                  <App />
                </Router>
              </QueryClientProvider>
            </AlertProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </>,
);

reportWebVitals();
