const AppRoutes = {
  LANDING: "/",
  NOT_FIND: "*",
  WORKSPACES_SELECTION: "/",
  DASHBOARD: "/dashboard",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY_ACCOUNT: "/verify-email",
  RESET_PASSWORD: "/reset-password",
  INVITATION: "/invitation",
  INTEGRATION: "/integrations",
  TABLES: "/tables",
  NOTION_TABLES: "/notion-tables",
  CREATE_TABLE: "create-table",
  FORMS: "/forms",
  CREATE_FORM: "create-form",
  FORM: "/form",
  UPDATE_FORM: "update-form",
  AUTH_PACKAGE: "/pricing",
  TABLE_POC: "/table-poc",
  INSTRUCTIONS: "/instructions-notion-token",
  SETTINGS: "/settings",
  PROFILE: "/profile",
  SCREEN_ACCESS: "screen-access",
  ROLES: "roles",
  TEMPLATE_ROLES: "/template-roles",
  ROLE_DETAILS: "roles-details",
  SCREENS: "screens",
  USERS: "users",
  API_KEYS: "api-keys",
  PERMISSIONS: "permissions",
  INVITES: "invites",
  ACCEPT_INVITATION: "/verify-invite",
  ASSIGN_TABLES: "assign-tables",

  TEMPLATES: "/templates",
  TEMPLATE_REVIEW: "template-review",
  SELECTED_TABLES: "/selected-tables",
  ASSIGNED_FORMS: "assigned-forms",
  ASSIGN_TABLE_MEMBERS: "/assign-table-members",
  ASSIGN_FORM_MEMBERS: "/assign-form-members",

  GROUPS: "groups",
  NOTION_TABLE_DATA: "notion-data",

  // Package Loader
  PACKAGES: "/packages",
  PACKAGE_PAYMENT: "/package-payment",
  PACKAGE_INVOICE: "/invoice",
  INVOICE_DETAILS: "/invoice-details",
  PACKAGE_EXPIRE: "/package-expired",

  // @forms
  FORMS_GROUP: "forms-groups",
  FORMS_THEME: "/forms-theme",
  FORMS_THEME_PREVIEW: "/form-theme-preview",
  FORM_THEME_CREATE: "/create-form-theme",
  FORM_THEME_EDIT: "/update-form-theme",
  PAYMENT: "/payment",

  UN_AUTHORIZED_PAGE: "/unauthorized-page",
  GOOGLE_AUTH_CALLBACK: "/auth/google",
};

export default AppRoutes;
