import { createSlice } from "@reduxjs/toolkit";
import { ITable, INotionTable } from "interfaces/tables";
import { useAppDispatch } from "redux/store";

interface IObjectPermission {
  isAllAccess: boolean;
  controls: string[];
  tableName: string;
}
export interface ITableReducer {
  table?: ITable | INotionTable | null;
  rowId?: string | null;
  tableObjects?: IObjectPermission | null;
}

const initialState: ITableReducer = {
  table: null,
  rowId: "",
  tableObjects: null,
};

interface IPayload {
  table?: ITable | INotionTable | null;
  rowId?: string | null;
  tableObjects?: IObjectPermission;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const usersSlice = createSlice({
  name: "tables",
  initialState,
  reducers: {
    setTable: (state, action: IAction) => {
      const newState = state;
      newState.table = action.payload.table;
    },
    setRowId: (state, action: IAction) => {
      const newState = state;
      newState.rowId = action.payload.rowId;
    },
    resetRowId: (state) => {
      const newState = state;
      newState.rowId = "";
    },
    removeTable: (state) => {
      const newState = state;
      newState.table = null;
    },
    setTableObjects: (state, action: IAction) => {
      const newState = state;
      newState.tableObjects = action.payload.tableObjects;
    },
    resetTableObjects: (state) => {
      const newState = state;
      newState.tableObjects = null;
    },
  },
});

export const {
  setTable,
  removeTable,
  setRowId,
  resetRowId,
  setTableObjects,
  resetTableObjects,
} = usersSlice.actions;

export const useTableActions = () => {
  const dispatch = useAppDispatch();
  return {
    setTable: (payload: IPayload) => dispatch(setTable(payload)),
    setRowId: (payload: IPayload) => dispatch(setRowId(payload)),
    setTableObjects: (payload: IPayload) => dispatch(setTableObjects(payload)),
    resetTableObjects: () => dispatch(resetTableObjects()),
    resetRowId: () => dispatch(resetRowId()),
    removeTable: () => dispatch(removeTable()),
  };
};
const { reducer } = usersSlice;

export default reducer;
