import { Spinner } from "components/Loader";
import Loadable from "react-loadable";

export const Packages = Loadable({
  loader: () => import("pages/Admin/Packages"),
  loading: () => <Spinner />,
});

export const PackagePayment = Loadable({
  loader: () => import("pages/Admin/Packages/Screen/PaymentGateway"),
  loading: () => <Spinner />,
});

export const PackageInvoice = Loadable({
  loader: () => import("pages/Admin/Settings/Screens/Invoice"),
  loading: () => <Spinner />,
});

export const PackageExpire = Loadable({
  loader: () => import("pages/Admin/Packages/Screen/PackageExpire"),
  loading: () => <Spinner />,
});
