/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "interfaces/settings/users.interface";
import { useAppDispatch } from "redux/store";

export interface IUserReducer {
  user: IUser | null;
}

const initialState: IUserReducer = {
  user: null,
};

interface IPayload {
  user: IUser;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: IAction) => {
      state.user = action.payload.user;
    },
    removeUser: (state) => {
      state.user = null;
    },
  },
});

export const { setUser, removeUser } = usersSlice.actions;

export const useUsersActions = () => {
  const dispatch = useAppDispatch();
  return {
    setUser: (payload: IPayload) => dispatch(setUser(payload)),
    removeUser: () => dispatch(removeUser()),
  };
};
const { reducer } = usersSlice;

export default reducer;
