import { createSlice } from "@reduxjs/toolkit";
import { IInvite } from "interfaces/settings/invite.interface";
import { useAppDispatch } from "redux/store";

export interface IInviteReducer {
  invite: IInvite | null;
}

const initialState: IInviteReducer = {
  invite: null,
};

interface IPayload {
  invite: IInvite;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const inviteSlice = createSlice({
  name: "invites",
  initialState,
  reducers: {
    setInvite: (state, action: IAction) => {
      const newState = state;
      newState.invite = action.payload.invite;
    },
    removeInvite: (state) => {
      const newState = state;
      newState.invite = null;
    },
  },
});

export const { setInvite, removeInvite } = inviteSlice.actions;

export const useInvitesActions = () => {
  const dispatch = useAppDispatch();
  return {
    setInvite: (payload: IPayload) => dispatch(setInvite(payload)),
    removeInvite: () => dispatch(removeInvite()),
  };
};
const { reducer } = inviteSlice;

export default reducer;
