/* eslint-disable import/namespace */
import ProtectedRoute from "components/ProtectedRoute";
import useUserInfo from "hooks/useUserInfo";
import { Outlet, Route, Routes } from "react-router-dom";
import AppRoutes from "./appRoutes";
// loads
import * as AdminLoadable from "./ScreenContainer/AdminLoad";
import * as AuthLoadable from "./ScreenContainer/AuthLoad";
import * as SettingLoadable from "./ScreenContainer/SettingsLoad";
import * as PackagesLoadable from "./ScreenContainer/PackagesLoad";

function Application() {
  const { isLoggedIn } = useUserInfo();
  return (
    <Routes>
      {isLoggedIn ? (
        <Route element={<ProtectedRoute />}>
          <Route
            element={<AdminLoadable.Dashboard />}
            path={AppRoutes.DASHBOARD}
          />
          <Route
            index
            element={<AdminLoadable.SelectWorkSpace />}
            path={AppRoutes.WORKSPACES_SELECTION}
          />
          <Route
            index
            element={<AdminLoadable.SelectWorkSpace />}
            path={`${AppRoutes.WORKSPACES_SELECTION}/:id`}
          />
          <Route
            index
            element={<AdminLoadable.Profile />}
            path={AppRoutes.PROFILE}
          />
          <Route
            index
            element={<PackagesLoadable.Packages />}
            path={AppRoutes.PACKAGES}
          />
          <Route
            index
            element={<PackagesLoadable.PackageExpire />}
            path={AppRoutes.PACKAGE_EXPIRE}
          />

          <Route
            index
            element={<PackagesLoadable.PackagePayment />}
            path={`${AppRoutes?.PACKAGE_PAYMENT}/:token`}
          />
          <Route
            index
            element={<PackagesLoadable.PackageInvoice />}
            path={`${AppRoutes?.PACKAGE_INVOICE}`}
          />
          <Route
            element={<AdminLoadable.AcceptInvitation />}
            path={`${AppRoutes.ACCEPT_INVITATION}/:token`}
          />
          <Route
            element={<AdminLoadable.Integration />}
            path={AppRoutes.INTEGRATION}
          />
          <Route
            element={<AdminLoadable.Instructions />}
            path={AppRoutes.INSTRUCTIONS}
          />
          <Route element={<Outlet />} path={AppRoutes.TABLES}>
            <Route
              index
              element={<AdminLoadable.Tables />}
              path={AppRoutes.TABLES}
            />
            <Route
              index
              element={<AdminLoadable.CreateNativeTable />}
              path={AppRoutes.CREATE_TABLE}
            />

            <Route
              index
              element={<AdminLoadable.NotionTableData />}
              path={`${AppRoutes.NOTION_TABLE_DATA}/:tableID`}
            />
          </Route>
          <Route
            element={<Outlet />}
            path={`${AppRoutes.FORMS}/:from/:objectID`}
          >
            <Route
              index
              element={<AdminLoadable.Forms />}
              path={`${AppRoutes.FORMS}/:from/:objectID`}
            />
            <Route
              index
              element={<AdminLoadable.CreateForm />}
              path={AppRoutes.CREATE_FORM}
            />
            <Route
              index
              element={<AdminLoadable.CreateForm />}
              path={`${AppRoutes.UPDATE_FORM}/:id`}
            />
          </Route>
          <Route element={<Outlet />} path={AppRoutes.FORMS}>
            <Route
              index
              element={<AdminLoadable.Forms />}
              path={AppRoutes.FORMS}
            />
            <Route
              index
              element={<AdminLoadable.CreateForm />}
              path={AppRoutes.CREATE_FORM}
            />
            <Route
              index
              element={<AdminLoadable.CreateForm />}
              path={`${AppRoutes.UPDATE_FORM}/:id`}
            />
          </Route>
          <Route element={<AdminLoadable.Tables />} path={AppRoutes.TABLES} />

          <Route element={<Outlet />} path={AppRoutes.SETTINGS}>
            <Route
              index
              element={<SettingLoadable.Settings />}
              path={AppRoutes.SETTINGS}
            />
            <Route
              element={<SettingLoadable.Permissions />}
              path={AppRoutes.PERMISSIONS}
            />
            <Route
              element={<SettingLoadable.APIKeys />}
              path={AppRoutes.API_KEYS}
            />

            <Route
              element={<SettingLoadable.ScreenAccess />}
              path={AppRoutes.SCREEN_ACCESS}
            />

            <Route element={<SettingLoadable.Roles />} path={AppRoutes.ROLES} />

            <Route element={<Outlet />} path={AppRoutes.ROLES}>
              <Route
                element={<SettingLoadable.RoleDetails />}
                path={`${AppRoutes?.ROLE_DETAILS}/:type/:token`}
              />
            </Route>

            <Route
              element={<SettingLoadable.Screens />}
              path={AppRoutes.SCREENS}
            />
            <Route element={<SettingLoadable.Users />} path={AppRoutes.USERS} />
            <Route
              element={<SettingLoadable.Invites />}
              path={AppRoutes.INVITES}
            />
            <Route
              element={<SettingLoadable.AssignTables />}
              path={AppRoutes.ASSIGN_TABLES}
            />
            <Route
              element={<SettingLoadable.AssignForms />}
              path={AppRoutes.ASSIGNED_FORMS}
            />

            <Route
              element={<SettingLoadable.Groups />}
              path={AppRoutes.GROUPS}
            />
            {/* Forms-Groups */}
            <Route
              element={<SettingLoadable.FormsGroups />}
              path={AppRoutes.FORMS_GROUP}
            />
          </Route>
          <Route
            element={<SettingLoadable.AccessTableMembers />}
            path={`${AppRoutes.ASSIGN_TABLE_MEMBERS}`}
          />

          <Route
            element={<SettingLoadable.AccessFormMembers />}
            path={`${AppRoutes.ASSIGN_FORM_MEMBERS}`}
          />

          <Route
            element={<AuthLoadable.Form />}
            path={`${AppRoutes.FORM}/:id`}
          />
          <Route
            element={<AuthLoadable.PagesNotFind />}
            path={AppRoutes.NOT_FIND}
          />
          <Route
            element={<AuthLoadable.Form />}
            path={`${AppRoutes.FORM}/:id`}
          />
          <Route
            element={<SettingLoadable.FormsTheme />}
            path={AppRoutes.FORMS_THEME}
          />
          <Route
            element={<SettingLoadable.FormsThemePreview />}
            path={`${AppRoutes.FORMS_THEME_PREVIEW}/:ids`}
          />
          <Route
            element={<SettingLoadable.FormThemeCreate />}
            path={AppRoutes.FORM_THEME_CREATE}
          />
          <Route
            element={<SettingLoadable.FormThemeCreate />}
            path={`${AppRoutes.FORM_THEME_EDIT}/:ids`}
          />
          <Route element={<Outlet />} path={AppRoutes.TEMPLATES}>
            <Route
              index
              element={<AdminLoadable.Templates />}
              path={AppRoutes.TEMPLATES}
            />
            <Route
              element={<AdminLoadable.TemplateReview />}
              path={`${AppRoutes.TEMPLATE_REVIEW}/:url`}
            />
          </Route>
          <Route
            element={<SettingLoadable.Roles />}
            path={`${AppRoutes.TEMPLATE_ROLES}/:from`}
          />
          <Route
            element={<AdminLoadable.SelectedTables />}
            path={`${AppRoutes.SELECTED_TABLES}/:templatename`}
          />
        </Route>
      ) : (
        <>
          <Route element={<AuthLoadable.Login />} path={AppRoutes.SIGN_IN} />
          <Route
            element={<AuthLoadable.Packages />}
            path={AppRoutes.AUTH_PACKAGE}
          />
          <Route
            element={<AdminLoadable.AcceptInvitation />}
            path={`${AppRoutes.ACCEPT_INVITATION}/:token`}
          />
          <Route
            element={<AuthLoadable.PagesNotFind />}
            path={AppRoutes.NOT_FIND}
          />
          <Route
            element={<AuthLoadable.Form />}
            path={`${AppRoutes.FORM}/:id`}
          />
          <Route element={<AuthLoadable.SingUp />} path={AppRoutes.SIGN_UP} />
          {/* <Route element={<AuthLoadable.Landing />} path={AppRoutes.LANDING} /> */}
          <Route
            element={<AuthLoadable.ForgotPassword />}
            path={AppRoutes.FORGOT_PASSWORD}
          />
        </>
      )}
      <Route
        element={<AuthLoadable.VerifyAccount />}
        path={`${AppRoutes.VERIFY_ACCOUNT}/:token`}
      />

      <Route
        element={<AuthLoadable.ResetPassword />}
        path={`${AppRoutes.RESET_PASSWORD}/:token`}
      />
      <Route
        element={<AuthLoadable.Invitation />}
        path={`${AppRoutes.INVITATION}/:token`}
      />
      <Route element={<AuthLoadable.Form />} path={`${AppRoutes.FORM}/:id`} />
      <Route
        element={<AuthLoadable.GoogleAuthCallBack />}
        path={`${AppRoutes.GOOGLE_AUTH_CALLBACK}/:token`}
      />
    </Routes>
  );
}

export default Application;
