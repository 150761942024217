import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface IUserState {
  token: string;
}

const initialState: IUserState = {
  token: "",
};

interface IPayload {
  token: string;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const authSlice = createSlice({
  name: "pendingInvitation",
  initialState,
  reducers: {
    setPendingInvitation: (state, action: IAction) => {
      const newState = state;
      newState.token = action.payload.token;
    },
  },
});

export const { setPendingInvitation } = authSlice.actions;

export const usePendingInvitationActions = () => {
  const dispatch = useAppDispatch();
  return {
    setPendingInvitation: (payload: IPayload) =>
      dispatch(setPendingInvitation(payload)),
  };
};
const { reducer } = authSlice;
export default reducer;
