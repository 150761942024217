/* eslint-disable object-shorthand */
/* eslint-disable prettier/prettier */
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";
import user from "./slices/auth";
import invites from "./slices/invite";
import permissions from "./slices/permissions";
import roles from "./slices/roles";
import screens from "./slices/screen";
import users from "./slices/users";
import tables from "./slices/tables";
import pendingInvitation from "./slices/pendingInvitation";
import permissionGuard from "./slices/permissionGuard";
import accessTableMembers from "./slices/accessTableMembers";
import theme from "./slices/themeEdit";

const persistConfig = {
  key: "root",
  storage,
};

const combinedReducer = combineReducers({
  user,
  permissions,
  roles,
  screens,
  users,
  invites,
  tables,
  pendingInvitation,
  permissionGuard,
  accessTableMembers,
  theme,
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { persistor, store };
