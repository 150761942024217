import { Info } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Tooltip,
  DialogTitle,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import Imagespath from "assets/images";
import palette from "theme/palette";
import { v4 as uuid } from "uuid";

type IProps = {
  open: boolean;
  title: string;
  message: string;
  cancelText: string;
  confirmText: string;
  handleClose: () => void;
  onConfirm: () => void;
  infoLabel?: string;
  onInfoClick?: () => void;
  isLoading: any;
};

function Alert(props: IProps) {
  const {
    open,
    message,
    handleClose,
    onConfirm,
    cancelText,
    confirmText,
    infoLabel,
    onInfoClick,
    isLoading,
  } = props;

  return (
    <Dialog id={uuid()} open={open}>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "15px",
            color: palette.error.main,
          }}
        >
          LockBoxy Alert!
        </Typography>
        <Box position="absolute" right={0} top={0}>
          {infoLabel ? (
            <Tooltip title={infoLabel}>
              <IconButton sx={{ mt: 1 }} onClick={onInfoClick}>
                <Info
                  fontSize="small"
                  sx={{
                    "&:hover": {
                      color: palette.primary.main,
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
          ) : null}

          <IconButton sx={{ mt: 1, mr: 1 }} onClick={handleClose}>
            <Close
              fontSize="small"
              sx={{
                "&:hover": {
                  color: palette.error.dark,
                },
              }}
            />
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", padding: 2 }}>
          <img
            alt="Warning"
            src={Imagespath?.Warning}
            style={{ height: 100, margin: "auto" }}
          />
        </Box>
        <Typography
          sx={{
            color: "#a9a9a9",
            textAlign: "center",
            fontSize: "11px",
            maxWidth: "300px",
          }}
        >
          {message}
        </Typography>
      </DialogTitle>
      <DialogActions>
        {cancelText ? (
          <Button
            sx={{
              textTransform: "none",
              margin: "auto",
              alignSelf: "baseline",
              marginBottom: "10px",

              backgroundColor: palette.error.main,
              "&:hover": {
                backgroundColor: palette.error.dark,
              },
              "&:disabled": {
                backgroundColor: palette.error.light,
              },
            }}
            variant="contained"
            onClick={handleClose}
          >
            {cancelText}
          </Button>
        ) : null}
        {confirmText ? (
          <Button
            sx={{
              textTransform: "none",
              margin: "auto",
              alignSelf: "baseline",
              marginBottom: "10px",
            }}
            variant="contained"
            onClick={onConfirm}
          >
            {isLoading ? (
              <CircularProgress color="warning" size={16} />
            ) : (
              confirmText
            )}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

export default Alert;
